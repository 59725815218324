@import './../../styles/_vars/colors';

.g-radio {
    position: relative;
    input[type="radio"] {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;

        &:checked + {
            label:not(.no-label) {
                color: $c-medium-gray;
            }

            label:not(.no-label):before {
                border-color: $c-red;
            }

            label:not(.no-label):after {
                transform: scale(1);
            }
        }
    }

    label:not(.no-label) {
        color: $c-medium-gray;
        font-weight: 600;
        cursor: pointer;
        transition: color .2s ease-in-out;
        &:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 25px;
            height: 25px;
            border: 2px solid $c-medium-gray;
            border-radius: 50%;
            margin-right: 10px;
            transition: border-color .2s ease-in-out;
        }

        &:after {
            content: '';
            position: absolute;
            width: 13px;
            height: 13px;
            transform: scale(0);
            border-radius: 50%;
            background-color: $c-red;
            left: 6px;
            top: 6px;
            transition: transform .2s ease-in-out;
            transform-origin: center center;
        }
    }

    &:hover {
        label:not(.no-label), label:not(.no-label):before {
            color: $c-medium-gray;
            border-color: $c-red;
        }
    }

    &--no-label {
        position: relative;
        input[type="radio"] {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;

            &:checked + {
                span {
                    color: $c-medium-gray;
                }

                span:before {
                    border-color: $c-red;
                }

                span:after {
                    transform: scale(1);
                }
            }
        }

        span {
            color: $c-light-gray;
            font-weight: 600;
            cursor: pointer;
            position: relative;
            display: inline-block;
            transition: color .2s ease-in-out;
            padding-left: 35px;
            &:before {
                content: '';
                position: absolute;
                width: 25px;
                height: 25px;
                border: 2px solid $c-light-gray;
                border-radius: 50%;
                transition: border-color .2s ease-in-out;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            &:after {
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                transform: scale(0);
                border-radius: 50%;
                background-color: $c-red;
                left: 6.35px;
                top: 50%;
                margin-top: -6px;
                transition: transform .2s ease-in-out;
                transform-origin: center center;
            }
        }

        &:hover {
            span, span:before {
                color: $c-medium-gray;
                border-color: $c-red;
            }
        }
    }

    &--sm {
        label:not(.no-label) {
            display: inline-block;
            position: relative;
            font-size: 13px;
            line-height: 20px;
            color: $c-dark-gray;
            &:before {
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: 12px;
                vertical-align: top;
            }
            &:after {
                width: 10px;
                height: 10px;
                left: 5px;
                top: 5px;
            }
        }
        input[type="radio"]:checked + label:not(.no-label) {
            color: $c-dark-gray;
        }
        &:hover {
            label:not(.no-label) {
                color: $c-dark-gray;
            }
        }
    }
}
