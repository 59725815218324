@import './../../styles/_vars/colors';

button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  font-family: 'Open Sans';
  outline: none;
  &[disabled],
  &.button--disabled {
    cursor: not-allowed;
    opacity: 0.6;
    user-select: none;
  }
}

.g-btn {
  background-color: $c-red;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(50, 50, 50, 0.3);
  color: $c-white;
  font-size: 14px;
  font-weight: 600;
  min-width: 140px;
  padding: 12px 20px;
  text-decoration: none;
  text-transform: uppercase;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;

  &:hover {
    background-color: $c-red;
  }

  &:active {
    background-color: $c-dark-red;
  }

  &--green {
    background-color: $c-green;
    &:hover {
      background-color: darken($c-green, 4%);
    }
    &:active {
      background-color: darken($c-green, 8%);
    }
  }

  &--gray {
    background-color: $c-extra-light-gray;
    color: $c-medium-gray;

    &:hover {
      background-color: darken($c-extra-light-gray, 4%);
    }
    &:active {
      background-color: darken($c-extra-light-gray, 8%);
    }
  }

  &--small {
    font-size: 12px;
    padding: 8px 16px;
  }

  &--big {
    font-size: 18px;
    padding: 14px 40px;
  }

  &--center {
    text-align: center;
  }
}

.g-btn-link {
  border-radius: 2px;
  color: $c-dark-gray;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 20px;
  text-transform: uppercase;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: $c-extra-light-gray;
  }
  &.active {
    background-color: $c-extra-light-gray;
  }

  > i.icon,
  > span {
    display: inline-block;
    vertical-align: middle;
  }

  &[disabled] {
    cursor: default;
    opacity: 0.5;
  }

  &[type='submit'],
  &--red {
    color: $c-red;
  }

  > i.icon {
    color: $c-red;
  }

  &--bordered {
    box-shadow: inset 0 0 1px 0 rgba(50, 50, 50, 0.3);
  }

  &--small {
    font-size: 12px;
    padding: 8px 16px;
  }
}

.g-btn-icon {
  color: $c-dark-gray;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  padding: 0 10px 0 0;
  text-decoration: none;
  > i.icon,
  > span {
    display: inline-block;
    vertical-align: middle;
  }

  > i.icon {
    color: $c-red;
    margin-right: 5px;
  }

  > i.icon {
    color: $c-red;
    margin-right: 5px;
  }

  &:hover {
    > i.icon {
      color: darken($c-red, 6%);
    }
  }
}

.g-btn-search {
  color: $c-medium-gray;
  padding: 0;
}

.g-btn-cell-control {
  color: $c-medium-gray;
  fill: $c-medium-gray;
  height: 24px;
  margin-left: 10px;
  padding: 0 5px;
  i.icon:before {
    font-size: 20px;
  }

  & + & {
    margin-left: 2px;
  }

  &:hover,
  &:active,
  &.active {
    color: $c-red;
  }

  &--big {
    color: $c-medium-gray;
    i.icon:before {
      font-size: 24px;
    }
  }
}

.g-btn-clear {
  padding: 0;
  span {
    border-bottom: 1px solid $c-light-blue;
    color: $c-blue !important;
    display: inline-block;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    transition: border-color 0.2s ease-in-out;
    vertical-align: middle;
  }

  i.icon {
    color: $c-red;
    display: inline-block;
    vertical-align: middle;
  }

  &:hover {
    span {
      border-color: $c-blue;
    }
    > i.icon {
      color: darken($c-red, 6%);
    }
  }
}

.g-btn-clear-gray {
  padding: 0;
  span {
    color: $c-medium-gray !important;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.88px;
    transition: border-color 0.2s ease-in-out;
    vertical-align: middle;
  }

  &:hover {
    span {
      border-color: $c-blue;
    }
  }
}

.g-btn-media {
  background-color: $c-red;
  border-bottom: 2px solid darken($c-red, 20%);
  border-radius: 2px;
  color: $c-white;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  min-width: 150px;
  padding: 10px 10px 8px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    border-bottom-width: 1px;
    margin-top: 1px;
  }
  &:active {
    border-bottom-width: 0;
    margin-top: 2px;
  }

  &--dark {
    background-color: $c-dark-gray;
    border-bottom: 2px solid darken($c-dark-gray, 20%);
  }

  &--simple {
    border-bottom: 0;
    text-transform: lowercase;
    &:hover,
    &:active {
      border-bottom: 0;
      margin-top: 0;
    }
  }

  &--small {
    font-size: 10px;
    min-width: 80px;
    padding: 2px 10px 4px;
  }
}

.g-btn-white {
  background-color: $c-white;
  border: 1px solid $c-extra-light-gray;
  border-bottom: 2px solid $c-extra-light-gray;
  border-radius: 2px;
  box-shadow: none;
  color: $c-red;
  display: inline-block;
  font-weight: 600;
  line-height: 19px;
  min-width: 140px;
  padding: 8px 10px 7px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    border-bottom-color: $c-red;
    color: $c-white;
  }
  &:active {
    border-bottom-color: darken($c-red, 20%);
    margin-top: 2px;
  }

  &--dark {
    background-color: $c-dark-gray;
    border-bottom: 2px solid darken($c-dark-gray, 20%);
  }

  &--simple {
    border-bottom: 0;
    text-transform: lowercase;
    &:hover,
    &:active {
      border-bottom: 0;
      margin-top: 0;
    }
  }

  &--small {
    font-size: 10px;
    min-width: 80px;
    padding: 2px 10px 4px;
  }
}

.g-btn-like {
  background-color: $c-white;
  border-radius: 50%;
  height: 40px;
  width: 40px;

  i.icon {
    color: $c-medium-gray;
    position: relative;
    top: 1px;
  }

  &--active {
    i.icon {
      color: $c-red;
    }
  }
}

.g-btn-tag {
  background-color: $c-white;
  border: 1px solid $c-extra-light-gray;
  border-radius: 4px;
  color: $c-dark-gray;
  font-size: 13px;
  font-weight: 500;
  min-width: 140px;

  padding: 10px 20px;
  position: relative;

  .icon-wrapper {
    background-color: $c-white;
    border-radius: 4px;
    color: $c-black;
    display: none;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 45px;

    &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
  }

  &:hover {
    background-color: $c-extra-light-gray;

    .icon-wrapper {
      background-color: $c-extra-light-gray;
      display: block;
      transition: background-color 0.2s ease-in-out;
    }
  }

  &--bordered {
    border: 1px solid $c-medium-gray;
  }

  &--active {
    background-color: $c-extra-light-gray;
    border-bottom-color: $c-red;
    border-bottom-width: 2px;
  }

  &--weight {
    border: 2px solid $c-medium-gray;
    /*box-shadow: 0 0 0 2px $c-link-default;
            border: none;*/
    font-weight: 600;
    padding: 9px 19px;
  }

  &--big {
    padding: 12px 20px;
  }

  &--small {
    min-width: auto;
    padding: 6px 10px;
  }
}

.g-btn-toggle {
  background-color: $c-white;
  border: 1px solid $c-light-gray;
  border-radius: 3px;

  color: $c-medium-gray;
  display: inline-block;
  font-size: 13px;
  line-height: 16px;
  margin: 0 5px 5px 0;

  padding: 7px 10px;

  // INARI-7435 - sticky hover
  &--active {
    background-color: $c-blue;
    border-color: $c-blue;
    color: $c-white;
  }
  //&:hover:not(&--active) {
  //    color: $c-white;
  //    background-color: $c-light-blue;
  //    border-color: $c-light-blue;
  //}
}

.g-btn-material {
  &[disabled] {
    background-color: $c-white;
    color: $c-medium-gray-for-icon;
    cursor: not-allowed;
    line-height: normal;
    user-select: none;
  }
  &:hover {
    &[disabled] {
      background-color: $c-extra-light-gray;
    }
    &:not([disabled]) {
      background-color: $c-white;
      color: $c-blue;
    }
  }
  background-color: $c-white;
  border: 1px solid $c-light-gray;
  border-radius: 4px;
  box-sizing: border-box;

  color: $c-dark-gray;
  display: inline-block;
  font-size: 13px;
  line-height: normal;
  margin: 0 5px 5px 0;

  padding: 8px 15px;

  &--active {
    background-color: $c-white;
    border-color: $c-blue;
    color: $c-blue;
  }
}

.g-btn-size {
  background-color: $c-white;
  border: 1px solid $c-light-gray;
  border-radius: 4px;
  box-sizing: border-box;

  color: $c-dark-gray;
  display: inline-block;
  font-size: 13px;
  line-height: normal;
  margin: 0 5px 5px 0;

  padding: 8px 12px;

  &--active {
    background-color: $c-white;
    border-color: $c-blue;
    color: $c-blue;
  }
}

.g-btn-lowercase {
  height: 36px;
  text-transform: none;
}
/**
* Backward button styles
**/
.g-backward {
  margin-bottom: 20px;
  &--pad {
    margin: 0;
    padding: 14px 0;
  }
}

.g-btn-rounded {
  background-color: $c-red;
  border-radius: 10px;
  color: $c-white;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  padding: 6px 40px;
  text-decoration: none;
}

.g-btn-flat {
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  min-width: 135px;
  outline: none;
  padding: 6px 20px;
  text-decoration: none;
  transition: background-color 0.2s;
  border: {
    bottom-width: 2px;
    left-width: 1px;
    radius: 2px;
    right-width: 1px;
    top-width: 1px;
  }

  &--red {
    background-color: $c-red;
    color: $c-white;
    border: {
      bottom-color: $c-dark-red;
      color: $c-red;
      style: solid;
    }

    &:hover {
      background-color: $c-dark-red;
    }
  }

  &--white {
    background-color: $c-white;
    color: $c-dark-gray;
    border: {
      color: $c-light-gray;
      style: solid;
    }

    &:hover {
      background-color: $c-light-gray;
    }
  }

  &--gray {
    background-color: $c-extra-light-gray;
    color: $c-medium-gray;
    border: {
      color: $c-extra-light-gray;
      style: solid;
    }

    &:hover {
      background-color: $c-extra-light-gray;
    }
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
    user-select: none;
  }

  .g-form-button--right & {
    margin-left: 10px;
  }
}

.g-btn-enter {
  background-color: $c-white;
  border: 1px solid $c-blue;
  border-radius: 2px;

  color: $c-blue;
  display: inline-block;
  font-size: 14px;
  line-height: 16px;

  padding: 8px 37px 8px 37px;
}
