// Нигде не используется???

@import './../../styles/_vars/colors';

.form-file-panel {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
    max-width: 450px;
    background-color: $c-extra-light-gray;
    padding: 10px;
    display: flex;
    justify-content: space-between;

    &__title {
        display: flex;
        align-items: center;
        overflow: hidden;
        i.icon {
            color: $c-red;
            margin-right: 5px;
            flex: 0 0 24px;
        }

        .g-blue-link {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
