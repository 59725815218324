@import './../../styles/_vars/colors';

.g-form-control {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid $c-light-gray;
    border-radius: 0;
    outline: none;
    height: 30px;
    width: 100%;
    padding: 0;
    box-shadow: none;
    box-sizing: border-box;
    transition: all 0.3s;
    font-family: inherit;
    font-size: 14px;
    color: $c-dark-gray;

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px $c-white inset;
    }

    &.ng-valid[required], &.ng-valid.required {
        border-bottom-color: $c-light-gray;

        &:hover {
            border-bottom-color: $c-light-red;
        }
    }

    &.ng-touched.ng-invalid,
    &.invalid {
        border-bottom-color: $c-light-red !important;

        & + label {
            color: $c-red;
        }
    }

    &:hover {
        border-bottom-color: $c-medium-gray;
        & + label {
            color: $c-medium-gray;
        }
    }

    &:focus {
        border-bottom-color: $c-medium-gray;
        & + label, & + .suggestions-wrapper + label {
            right: auto;
            color: $c-medium-gray;
            transform: translate3d(0, -15px, 0) scale(.75);
            transition: transform .4s cubic-bezier(.25, .8, .25, 1), width .4s cubic-bezier(.25, .8, .25, 1);
        }
    }

    &[disabled], &[readonly="true"] {
        user-select: none;
        cursor: not-allowed;

        &:focus + label {
            transform: none;
        }
    }
}

.g-form-control-wrapper {
    position: relative;
}

textarea {
    height: 150px;

    &:not(.g-form-control-bordered) {
        border: none;
        color: $c-dark-gray;
        font-size: 13px;
        line-height: 1.5;
        background-color: $c-extra-light-gray;
        width: 100%;
        padding: 10px;
        outline: none;
        margin-top: 15px;
        resize: none;
    }
}

.g-input-file-image {
    width: 40px;
    height: 40px;
    border: 1px solid $c-extra-light-gray;
    border-radius: 3px;
    position: relative;
    display: inline-flex;
    align-items: center;

    label {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left:0;
        cursor: pointer;
        overflow: hidden;

        img {
            margin: auto;
            max-width: 100%;
            width: 100%;
            height: auto;
        }

    }

    i.icon-ic_close, i.icon-ic_eye_passw {
        display: none;
        width: 22px;
        height: 22px;
        margin: 0;
        border: 1px solid $c-extra-light-gray;
        background-color: $c-white;
        border-radius: 50%;
        position: absolute;
        color: $c-red;
        cursor: pointer;

        &:before {
            font-size: 20px;
            transition: all .2s ease-in-out;
        }

        &.active {
            display: block;
        }
    }

    i.icon-ic_close {
        bottom: -7px;
        right: -7px;
    }

    i.icon-ic_eye_passw {
        top: -7px;
        left: -7px;
    }

    &:hover {
        i.icon-ic_close, i.icon-ic_eye_passw {
            display: block;
        }
    }

    i.icon-ic_photo {
        color: $c-medium-gray;
        margin: auto;
    }

    input[type="file"] {
        display: none;
    }
}

.g-input-window {
    width: 102px;
    height: 42px;
    border: 1px solid $c-extra-light-gray;
    border-radius: 3px;
    text-align: center;
    font-size: 16px;
    position: relative;

    &:focus {
        outline: none;
        border: 1px solid $c-red;
        border-bottom-width: 3px;
    }

    &--small {
        width: 70px;
        height: 30px;
        border-radius: 3px;
        font-size: 14px;

        &:focus {
            outline: none;
            border: 1px solid $c-extra-light-gray;
        }
    }
}

.g-round-close-button {
    display: inline-block;
    width: 22px;
    height: 22px;
    margin: 0;
    border: 1px solid $c-extra-light-gray;
    background-color: $c-white;
    border-radius: 50%;
    color: $c-red;
    cursor: pointer;

    &:before {
        font-size: 20px;
    }
}
