@import './../../styles/_vars/colors';

.g-form-group {
    position: relative;
    margin-bottom: 20px;

    & > label:not(.static) {
        color: $c-medium-gray;
        position: absolute;
        top: 8px;
        right: 0;
        left: 0;
        font-weight: 300;
        font-size: 14px;
        cursor: text;
        transform-origin: left top;
        user-select: none;
        transition: transform .4s cubic-bezier(.25,.8,.25,1),-webkit-transform .4s cubic-bezier(.25,.8,.25,1);
        pointer-events: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &.active {
            transition: none;
            transform: translate3d(0, -15px,0) scale(.75) !important;
            right: auto;
        }

        &.overlay {
            color: $c-medium-gray;
            background-color: $c-light-gray;
            height: 30px;
            top: 0;
            padding: 8px 5px;
            font-size: 12px;
            transition: none;
            border-bottom: 2px solid $c-medium-gray;
            .icon {
                position: absolute;
                right: 3px;
                top: 3px;
            }
        }

        &.std-label {
            position: static;
            display: block;
        }
    }

    label.static {
        display: block;
        font-size: 12px;
        line-height: 18px;
        font-weight: 600;
        -webkit-font-smoothing: antialiased;
        margin-bottom: 4px;

        &.focus {
            color: $c-blue;
        }

        &.error {
            color: $c-red;
        }
    }

    &--search {
        & > label {
            right: 30px;
        }

        .g-form-control {
            padding-right: 30px;
        }

        .suggestions-addon {
            display: none !important;
        }
    }

    &--password-icon {
        position: relative;

        .eye-icon {
            position: absolute;
            top: 5px;
            right: 0;
            cursor: pointer;
            > i.icon {
                &:before {
                    font-size: 24px;
                }
                color: $c-medium-gray;
            }

            &.active {
                > i.icon {
                    color: $c-red;
                }
            }

            &:hover {
                > i.icon {
                    color: lighten($c-medium-gray, 10%);
                }
            }
        }
    }

    // MARGIN-TOP
    &--mt {
        margin-top: 20px;
    }

    // MARGIN BOTTOM
    &--large-mb {
        margin-bottom: 40px;
    }

    .g-alert {
        //position: absolute;
        bottom: -13px;
        font-size: 12px;
        padding: 5px 2px 0;
        margin: 0;
        color: $c-black;
        background-color: transparent;
    }

    .g-alert.g-alert-danger {
        color: $c-red;
    }

}


.g-form-group-icon {
    position: absolute;
    right: 0;
    top: 0;
    height: 24px;
    margin-top: auto;
    margin-bottom: auto;
    color: $c-medium-gray;
    fill: $c-medium-gray;
}
