@import './../../styles/_vars/colors';

.g-alert {
    &.g-alert-danger {
        padding: 0.375rem 0.5625rem 0.5625rem;
        margin-top: -1px;
        margin-bottom: 1rem;
        background: $c-red;
        color: $c-white;
    }
}
