@import "../_mixins/mq";
@import './../../styles/_vars/colors';

.g-form-title {
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
    color: $c-dark-gray;
    margin: 0 0 20px;

    &--center {
        text-align: center;
    }

    &--sm {
        font-size: 16px;
    }

    &--no-upper {
        text-transform: none;
    }

    &--grey {
        color: $c-dark-gray;
    }

    &--underline {
        padding-bottom: 20px;
        border-bottom: 1px solid $c-light-gray;
    }

    &--pad {
        padding: 10px 0 30px;
    }

    &--leftline {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        &:after {
            content: '';
            height: 1px;
            width: 100%;
            flex: 1;
            background-color: $c-light-gray;
            margin-left: 10px;
        }
    }

    &--nomargin {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    &--nomargin-bottom {
        margin-bottom: 0;
    }

    &--nopadding-bottom {
        padding-bottom: 0;
    }
}

.g-form-subtitle {
    color: $c-dark-gray;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 14px;
    text-transform: none;

    &--center {
        text-align: center;
    }

    &--large-mb {
        margin-bottom: 30px;
    }
}

@include mq($until: desktop) {
    .g-form-title--pad {
        padding: 10px 0 20px;
    }
}
