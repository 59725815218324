@import './../../styles/_vars/colors';

.g-form-error-tooltip {
    .g-form-control-wrapper & {
        position: absolute;
        top: 9px;
        right: 10px;
        z-index: 30;
    }
}

.g-form-error-tooltip__icon {
    .icon {
        color: $c-red;
        cursor: pointer;

        &::before {
            font-size: 17px;
        }
    }
}

.g-form-error-tooltip__popover {
    position: absolute;
    top: 100%;
    right: -10px;
    max-width: 440px;
    padding: 6px 8px;
    margin-top: 5px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
    background: $c-white;
    box-shadow: 0 0 4px rgba($c-black, 0.25);

    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        right: 18px;
        bottom: 100%;
        border: 5px solid $c-white;
        border-top-color: transparent;
        border-left-color: transparent;
    }
}
