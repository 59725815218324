@import './../../styles/_vars/colors';

.g-checkbox {
  position: relative;

  input[type='checkbox'] {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    &:checked + label:not(.no-label) {
      span {
        color: $c-light-red;
      }
    }
    &:checked + label:not(.no-label) {
      &:before {
        background-color: rgba(255, 82, 82, 1);
        border-color: transparent;
      }
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }

    &:disabled:not(:checked) + label:not(.no-label) {
      color: rgba(0, 0, 0, 0.38);
      cursor: default;
      &:before {
        border-color: rgba(0, 0, 0, 0.38);
      }
    }

    &:checked:disabled + label:not(.no-label) {
      cursor: default;
      &:before {
        background-color: rgba(0, 0, 0, 0.38);
        border-color: transparent;
      }
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  label:not(.no-label) {
    cursor: pointer;
    display: inline-block;
    height: 20px;
    position: relative;
    width: 20px;

    &:before {
      border: 2px solid $c-medium-gray;
      border-radius: 2px;
      content: '';
      display: inline-block;
      height: 20px;
      margin-right: 10px;
      position: relative;
      top: 0;
      transition: all 0.2s ease-in-out;
      vertical-align: middle;
      width: 20px;
    }
    &:after {
      border-color: $c-white;
      border-style: solid;
      border-width: 0 2px 2px 0;
      bottom: 0;
      content: '';
      height: 13.33333px;
      left: 7px;
      margin-bottom: auto;
      margin-top: auto;
      opacity: 0;
      position: absolute;
      top: -3px;
      transform: rotate(45deg);
      visibility: hidden;
      width: 6.66667px;
    }

    &:hover:before {
      border-color: $c-light-red;
    }
  }

  &--intermediate {
    label:not(.no-label) {
      &:before {
        background-color: $c-medium-gray;
      }
      &:after {
        background-color: $c-white;
        border-color: transparent;
        height: 2px;
        left: 4.5px;
        opacity: 1;
        top: -1px;
        transform: rotate(0);
        visibility: visible;
        width: 10px;
      }
    }

    &.g-checkbox--with-text label:not(.no-label):after {
      left: -27px;
    }
  }

  &--limit {
    input[type='checkbox']:not(:checked) + label:not(.no-label) {
      pointer-events: none;
      &:before {
        background-color: $c-medium-gray;
      }
      &:after {
        background-color: $c-white;
        border-color: transparent;
        height: 2px;
        left: 4.5px;
        opacity: 1;
        top: 0;
        visibility: visible;
        width: 10px;
      }
    }

    &.g-checkbox--with-text {
      input[type='checkbox']:not(:checked) + label:not(.no-label):after {
        left: -27px;
      }
    }
  }

  &--inverted {
    input[type='checkbox'] {
      &:checked + label:not(.no-label) {
        &:before {
          opacity: 1;
          visibility: visible;
        }
        &:after {
          background-color: rgba(255, 82, 82, 0.87);
          border-color: transparent;
        }
      }

      &:disabled:not(:checked) + label:not(.no-label) {
        color: rgba(0, 0, 0, 0.38);
        cursor: default;
        &:before {
          border-color: rgba(0, 0, 0, 0.38);
        }
        &:after {
          border-color: rgba(0, 0, 0, 0.38);
        }
      }

      &:checked:disabled + label:not(.no-label) {
        cursor: default;
        &:before {
          background-color: transparent;
          border-color: transparent;
          opacity: 1;
          visibility: visible;
        }
        &:after {
          background-color: rgba(0, 0, 0, 0.38);
          border-color: transparent;
          opacity: 1;
          visibility: visible;
        }
      }
    }
    label:not(.no-label) {
      &:before {
        background: transparent;
        border-color: $c-white !important;
        border-style: solid;
        border-width: 0 2px 2px 0;
        bottom: 0;
        content: '';
        height: 13.33333px;
        margin-bottom: auto;
        margin-top: auto;
        opacity: 0;
        position: absolute;
        right: -14px;
        top: -3px;
        transform: rotate(45deg);
        visibility: hidden;
        width: 6.66667px;
        z-index: 4;
      }
      &:after {
        border: 2px solid $c-medium-gray;
        border-radius: 2px;
        content: '';
        display: inline-block;
        height: 20px;
        left: 0;
        margin-left: 10px;
        opacity: 1;
        position: relative;
        top: 0;
        transform: rotate(0);
        transition: all 0.2s ease-in-out;
        vertical-align: middle;
        visibility: visible;
        width: 20px;
      }

      &:hover:after {
        border-color: $c-light-red;
      }
    }

    &.g-checkbox--with-text label:not(.no-label) {
      margin-left: 0;
      margin-right: 32px;
      &:before {
        margin-left: 0;
        right: -32px;
      }
      &:after {
        left: inherit;
        left: initial;
        right: -28px;
      }
    }
  }

  &--with-text {
    label:not(.no-label) {
      margin-left: 32px;
      width: auto;
      span {
        color: $c-medium-gray;
        vertical-align: top;
      }

      &:before {
        content: '';
        margin-left: -32px;
        position: absolute;
      }
      &:after {
        bottom: 0;
        content: '';
        left: -25px;
        position: absolute;
      }
    }
  }

  &--no-label {
    position: relative;
    input[type='checkbox'] {
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      visibility: hidden;
      &:checked + span {
        span {
          color: $c-light-red;
        }
      }
      &:checked + span {
        &:before {
          background-color: rgba(255, 82, 82, 1);
          border-color: transparent;
        }
        &:after {
          opacity: 1;
          visibility: visible;
        }
      }

      &:disabled:not(:checked) + span {
        color: rgba(0, 0, 0, 0.38);
        cursor: default;
        &:before {
          border-color: rgba(0, 0, 0, 0.38);
        }
      }

      &:checked:disabled + span {
        cursor: default;
        &:before {
          background-color: rgba(0, 0, 0, 0.38);
          border-color: transparent;
        }
        &:after {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    span {
      cursor: pointer;
      display: inline-block;
      height: 20px;
      position: relative;
      width: 20px;

      &:before {
        border: 2px solid $c-light-gray;
        border-radius: 2px;
        content: '';
        display: inline-block;
        height: 20px;
        margin-right: 10px;
        position: relative;
        top: 0;
        transition: all 0.2s ease-in-out;
        vertical-align: middle;
        width: 20px;
      }
      &:after {
        border-color: $c-white;
        border-style: solid;
        border-width: 0 2px 2px 0;
        bottom: 0;
        content: '';
        height: 13.33333px;
        left: 7px;
        margin-bottom: auto;
        margin-top: auto;
        opacity: 0;
        position: absolute;
        top: -3px;
        transform: rotate(45deg);
        visibility: hidden;
        width: 6.66667px;
      }

      &:hover:before {
        border-color: $c-light-red;
      }
    }

    &.g-checkbox--with-text {
      span {
        margin-left: 32px;
        width: auto;
        span {
          color: $c-medium-gray;
          vertical-align: top;
        }

        &:before {
          content: '';
          margin-left: -32px;
          position: absolute;
        }
        &:after {
          bottom: 0;
          content: '';
          left: -25px;
          position: absolute;
        }
      }
    }

    &.g-checkbox--radio {
      input[type='radio'] {
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        visibility: hidden;
        &:checked + span {
          span {
            color: $c-light-red;
          }
        }
        &:checked + span {
          &:before {
            background-color: rgba(255, 82, 82, 1);
            border-color: transparent;
          }
          &:after {
            opacity: 1;
            visibility: visible;
          }
        }

        &:disabled:not(:checked) + span {
          color: rgba(0, 0, 0, 0.38);
          cursor: default;
          &:before {
            border-color: rgba(0, 0, 0, 0.38);
          }
        }

        &:checked:disabled + span {
          cursor: default;
          &:before {
            background-color: rgba(0, 0, 0, 0.38);
            border-color: transparent;
          }
          &:after {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}

.g-checkboxblue {
  margin-bottom: 8px;
  position: relative;

  input[type='checkbox'] {
    left: 10;
    opacity: 0;
    position: absolute;
    top: 0;
    visibility: hidden;

    &:checked + label:not(.no-label) {
      span {
        color: $c-dark-gray;
      }
    }
    &:checked + label:not(.no-label) {
      &:before {
        background-color: $c-blue;
        border-color: transparent;
      }
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }

    &:disabled:not(:checked) + label:not(.no-label) {
      color: rgba(0, 0, 0, 0.38);
      cursor: default;
      &:before {
        border-color: rgba(0, 0, 0, 0.38);
      }
    }

    &:checked:disabled + label:not(.no-label) {
      cursor: default;
      &:before {
        background-color: rgba(0, 0, 0, 0.38);
        border-color: transparent;
      }
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }

    &:disabled + label:not(.no-label) span {
      color: #9e9e9e;
    }
  }

  label:not(.no-label) {
    color: $c-dark-gray;
    cursor: pointer;
    display: inline-block;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    height: 20px;
    line-height: normal;
    position: relative;
    width: 20px;

    &:before {
      border: 2px solid $c-medium-gray;
      border-radius: 2px;
      content: '';
      display: inline-block;
      height: 20px;
      margin-right: 10px;
      position: relative;
      top: 0;
      //transition: all 0.2s ease-in-out;
      vertical-align: middle;
      width: 20px;
    }
    &:after {
      border-color: $c-white;
      border-left-color: $c-blue;
      border-style: solid;
      border-width: 0 2px 2px 0;
      bottom: 0;
      content: '';
      height: 13.33333px;
      left: 7px;
      margin-bottom: auto;
      margin-top: auto;
      opacity: 0;
      position: absolute;
      top: -3px;
      transform: rotate(45deg);
      visibility: hidden;
      width: 6.66667px;
    }

    &:hover:before {
      border-color: $c-blue;
    }
  }

  &--intermediate {
    label:not(.no-label) {
      &:before {
        background-color: $c-medium-gray;
      }
      &:after {
        background-color: $c-white;
        border-color: transparent;
        height: 2px;
        left: 4.5px;
        opacity: 1;
        top: -1px;
        transform: rotate(0);
        visibility: visible;
        width: 10px;
      }
    }

    &.g-checkbox--with-text label:not(.no-label):after {
      left: -27px;
    }
  }

  &--limit {
    input[type='checkbox']:not(:checked) + label:not(.no-label) {
      pointer-events: none;
      &:before {
        background-color: $c-medium-gray;
      }
      &:after {
        background-color: $c-white;
        border-color: transparent;
        height: 2px;
        left: 4.5px;
        opacity: 1;
        top: 0;
        visibility: visible;
        width: 10px;
      }
    }

    &.g-checkbox--with-text {
      input[type='checkbox']:not(:checked) + label:not(.no-label):after {
        left: -27px;
      }
    }
  }

  &--inverted {
    input[type='checkbox'] {
      &:checked + label:not(.no-label) {
        &:before {
          opacity: 1;
          visibility: visible;
        }
        &:after {
          background-color: rgba(255, 82, 82, 0.87);
          border-color: transparent;
        }
      }

      &:disabled:not(:checked) + label:not(.no-label) {
        color: rgba(0, 0, 0, 0.38);
        cursor: default;
        &:before {
          border-color: rgba(0, 0, 0, 0.38);
        }
        &:after {
          border-color: rgba(0, 0, 0, 0.38);
        }
      }

      &:checked:disabled + label:not(.no-label) {
        cursor: default;
        &:before {
          background-color: transparent;
          border-color: transparent;
          opacity: 1;
          visibility: visible;
        }
        &:after {
          background-color: rgba(0, 0, 0, 0.38);
          border-color: transparent;
          opacity: 1;
          visibility: visible;
        }
      }
    }
    label:not(.no-label) {
      &:before {
        background: transparent;
        border-color: $c-white !important;
        border-style: solid;
        border-width: 0 2px 2px 0;
        bottom: 0;
        content: '';
        height: 13.33333px;
        margin-bottom: auto;
        margin-top: auto;
        opacity: 0;
        position: absolute;
        right: -14px;
        top: -3px;
        transform: rotate(45deg);
        visibility: hidden;
        width: 6.66667px;
        z-index: 4;
      }
      &:after {
        border: 2px solid $c-medium-gray;
        border-radius: 2px;
        content: '';
        display: inline-block;
        height: 20px;
        left: 0;
        margin-left: 10px;
        opacity: 1;
        position: relative;
        top: 0;
        transform: rotate(0);
        transition: all 0.2s ease-in-out;
        vertical-align: middle;
        visibility: visible;
        width: 20px;
      }

      &:hover:after {
        border-color: $c-light-red;
      }
    }

    &.g-checkbox--with-text label:not(.no-label) {
      margin-left: 0;
      margin-right: 32px;
      &:before {
        margin-left: 0;
        right: -32px;
      }
      &:after {
        left: inherit;
        left: initial;
        right: -28px;
      }
    }
  }

  &--with-text {
    label:not(.no-label) {
      margin-left: 32px;
      width: auto;
      span {
        color: $c-dark-gray;
        vertical-align: top;
      }

      &:before {
        content: '';
        margin-left: -32px;
        position: absolute;
      }
      &:after {
        bottom: 0;
        content: '';
        left: -25px;
        position: absolute;
      }
    }
  }

  &--no-label {
    position: relative;
    input[type='checkbox'] {
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      visibility: hidden;
      &:checked + span {
        span {
          color: $c-light-red;
        }
      }
      &:checked + span {
        &:before {
          background-color: rgba(255, 82, 82, 1);
          border-color: transparent;
        }
        &:after {
          opacity: 1;
          visibility: visible;
        }
      }

      &:disabled:not(:checked) + span {
        color: rgba(0, 0, 0, 0.38);
        cursor: default;
        &:before {
          border-color: rgba(0, 0, 0, 0.38);
        }
      }

      &:checked:disabled + span {
        cursor: default;
        &:before {
          background-color: rgba(0, 0, 0, 0.38);
          border-color: transparent;
        }
        &:after {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    span {
      cursor: pointer;
      display: inline-block;
      height: 20px;
      position: relative;
      width: 20px;

      &:before {
        border: 2px solid $c-light-gray;
        border-radius: 2px;
        content: '';
        display: inline-block;
        height: 20px;
        margin-right: 10px;
        position: relative;
        top: 0;
        transition: all 0.2s ease-in-out;
        vertical-align: middle;
        width: 20px;
      }
      &:after {
        border-color: $c-white;
        border-style: solid;
        border-width: 0 2px 2px 0;
        bottom: 0;
        content: '';
        height: 13.33333px;
        left: 7px;
        margin-bottom: auto;
        margin-top: auto;
        opacity: 0;
        position: absolute;
        top: -3px;
        transform: rotate(45deg);
        visibility: hidden;
        width: 6.66667px;
      }

      &:hover:before {
        border-color: $c-light-red;
      }
    }

    &.g-checkbox--with-text {
      span {
        margin-left: 32px;
        width: auto;
        span {
          color: $c-medium-gray;
          vertical-align: top;
        }

        &:before {
          content: '';
          margin-left: -32px;
          position: absolute;
        }
        &:after {
          bottom: 0;
          content: '';
          left: -25px;
          position: absolute;
        }
      }
    }

    &.g-checkbox--radio {
      input[type='radio'] {
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        visibility: hidden;
        &:checked + span {
          span {
            color: $c-light-red;
          }
        }
        &:checked + span {
          &:before {
            background-color: rgba(255, 82, 82, 1);
            border-color: transparent;
          }
          &:after {
            opacity: 1;
            visibility: visible;
          }
        }

        &:disabled:not(:checked) + span {
          color: rgba(0, 0, 0, 0.38);
          cursor: default;
          &:before {
            border-color: rgba(0, 0, 0, 0.38);
          }
        }

        &:checked:disabled + span {
          cursor: default;
          &:before {
            background-color: rgba(0, 0, 0, 0.38);
            border-color: transparent;
          }
          &:after {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}
