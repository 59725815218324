@import './../../styles/_vars/colors';

.g-form-album {
    /*box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);*/
    max-width: 420px;
    width: 100%;
    background-color: $c-white;
    border: 1px solid $c-medium-gray;
    border-radius: 17px;
    padding: 20px;
    /*position: relative;*/
    &__img {
        //padding: 20px 20px 0;
        padding-bottom: 55px;
        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        /*padding-top: 15px;*/

        a {
            font-weight: 300;
            color: $c-blue;
            border-bottom: 1px dashed $c-medium-gray;
            transition: border-color .2s ease-in-out;
            line-height: 22px;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 90%;

            &:hover {
                border-color: $c-blue;
            }
        }
    }

    &__link {
        position: absolute;
        bottom: 0px;

        &--left {
            left: 0px;
        }

        &--right {
            right: -5px;
        }
    }
}
