@import './../../styles/_vars/colors';

.g-form-control-bordered {
    background-color: transparent;
    border: 1px solid $c-light-gray;
    border-radius: 4px;
    outline: none;
    height: 36px;
    width: 100%;
    padding: 10px;
    box-shadow: none;
    box-sizing: border-box;
    transition: all 0.3s;
    font-family: inherit;
    font-size: 14px;
    color: $c-dark-gray;

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px $c-white inset;
    }

    &.ng-valid[required], &.ng-valid.required {
        border-color: $c-light-gray;
    }

    &.ng-touched.ng-invalid,
    &.invalid {
        border-color: $c-red !important;
        &::placeholder {
            color: $c-red;
        }
    }

    &:hover {
        border-color: $c-blue;
    }

    &:focus {
        border-color: $c-blue;
    }

    &[disabled], &[readonly="true"] {
        user-select: none;
        cursor: not-allowed;
    }

    &::placeholder {
        color: $c-light-gray;
    }

    @at-root {
        textarea#{&} {
            height: 150px;
        }
    }
}
