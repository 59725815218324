.g-input {
    &__container {
        width: 250px;
        height: 36px;;
        display: flex;
        border: 1px solid $c-input-extra-light-gray;
        justify-content: space-between;
        align-items: center;

        font-family: Open Sans;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;

        span {
            color: $c-medium-gray;
            margin-left: 10px;
            margin-right: 10px;
        }

        color: $c-dark-gray;
    }
}

.g-input__container input:focus,
.g-input__container input:active {
    outline: none;
}

.g-input__container input {
    border: none;
    width: 100%;
}

.g-input-round input {
  height: 42px;
  width: 100%;

  background: $c-white;
  border: 1px solid $c-input-extra-light-gray;
  border-radius: 3px;
}
