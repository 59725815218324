$public_font_path: '/assets/fonts/';

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url($public_font_path + 'OpenSans-Bold.woff2') format('woff2'),
    url($public_font_path + 'OpenSans-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url($public_font_path + 'OpenSans-SemiBold.woff2') format('woff2'),
    url($public_font_path + 'OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url($public_font_path + 'OpenSans-Regular.woff2') format('woff2'),
    url($public_font_path + 'OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rotondac';
    src: local('Rotondac Bold'), local('Rotondac-Bold'),
    url($public_font_path + 'Rotondac-Bold.woff2') format('woff2'),
    url($public_font_path + 'Rotondac-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
