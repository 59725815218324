// USED BLACK
$c-black: #000000;
$c-black-shadow: #00000026;

// USED WHITE
$c-white: #ffffff;

// USED MEDIUM RED
$c-red: #f44336;

// USED LIGHT RED
$c-light-red: #ff7c72;

// USED DARK RED
$c-dark-red: #cc1c11;

// USED BLUE
$c-dark-blue: #45668e;

// USED MEDIUM BLUE
$c-blue: #0091d9;

// USED LIGHT BLUE
$c-light-blue: #b6e9ff;

$c-other-light-blue: #c9f1fb;

// USED YELLOW
$c-yellow: #ffff00;

// USED DARK GRAY
$c-dark-gray: #3d3d3d;

// USED MEDIUM GRAY
$c-medium-gray: #9e9e9e;
$c-medium-gray-for-icon: #c4c4c4;
$c-medium-gray-for-icon2: #d9d9d9;
// USED LIGHT GRAY
$c-light-gray: #ededed;
$c-light-gray2: #E5E5E5;

// USED EXTRA LIGHT GRAY
$c-extra-light-gray: #f6f6f6;
$c-extra-light-gray2: #D9D9D9;

$c-input-extra-light-gray: #eaeaea;

// USED GREEN
$c-green: #87b301;
$c-medium-green: #4FB746;
$c-bright-green: #0FB834;

// USED FOR PERFECT SCROLL BAR


