html {
  font-family: "Open Sans", sans-serif;
}

* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

.h20 {
  height: 20px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #e5e5e5;
  border-radius: 3px;
}
