@import './../../styles/_vars/colors';

select {
    background: $c-white;
    border: 1px solid $c-white;
    border-bottom: 2px solid $c-light-gray;
    height: 30px;
    color: $c-medium-gray;
    font-size: 14px;
    width: 100%;

    &:focus {
        outline: none;
        option:focus {
            outline: none;
        }
    }
}

.g-select {
    position: relative;
    border: 1px solid $c-extra-light-gray;
    border-radius: 3px;
    background-color: $c-white;

    &__select {
        display: block;
        font-size: inherit;
        color: inherit;
        border: 0;
        padding-right: 30px;
        padding-left: 10px;
        outline: none;
        background: transparent;
        -webkit-appearance:none; // remove select style in webkit browsers

        // remove select style in ie
        &::-ms-expand {
            display: none;
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 100%;
        pointer-events: none;
        background-color: $c-white;
        border-radius: 3px;

        i.icon {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            color: $c-red;
        }
    }
}
