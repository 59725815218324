.g-textarea {
    height: 150px;
    width: 90%;

    border: 1px solid $c-input-extra-light-gray;
    color: $c-medium-gray;;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    
    background-color: $c-extra-light-gray;
     
    padding: 10px;
    outline: none;
    margin-top: 15px;          
}