@font-face {
    font-family: 'iconfont1738762268';
    src: url('/assets/fonts/icons/iconfont1738762268.ttf') format('truetype'),
    url('/assets/fonts/icons/iconfont1738762268.woff') format('woff'),
    url('/assets/fonts/icons/iconfont1738762268.svg#iconfont1738762268') format('svg');
}

.icon:before {
    font-family: 'iconfont1738762268';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
       display: inline-block;
       vertical-align: middle;
       line-height: 1;
}


.icon-attach-file:before {
    content: '\E001';
    font-size: 24px;
}

.icon-attachment:before {
    content: '\E002';
    font-size: 24px;
}

.icon-baseline-check:before {
    content: '\E003';
    font-size: 24px;
}

.icon-calculator:before {
    content: '\E004';
    font-size: 24px;
}

.icon-card_red:before {
    content: '\E005';
    font-size: 24px;
}

.icon-cart:before {
    content: '\E006';
    font-size: 24px;
}

.icon-crop:before {
    content: '\E007';
    font-size: 24px;
}

.icon-design-sides:before {
    content: '\E008';
    font-size: 24px;
}

.icon-discount-box:before {
    content: '\E009';
    font-size: 24px;
}

.icon-equalizer:before {
    content: '\E00A';
    font-size: 24px;
}

.icon-facebook-with-circle:before {
    content: '\E00B';
    font-size: 24px;
}

.icon-facebook:before {
    content: '\E00C';
    font-size: 24px;
}

.icon-fill:before {
    content: '\E00D';
    font-size: 24px;
}

.icon-ic_access_time:before {
    content: '\E00E';
    font-size: 24px;
}

.icon-ic_account_circle:before {
    content: '\E00F';
    font-size: 24px;
}

.icon-ic_add:before {
    content: '\E010';
    font-size: 24px;
}

.icon-ic_add_circle:before {
    content: '\E011';
    font-size: 24px;
}

.icon-ic_arrow-back:before {
    content: '\E012';
    font-size: 24px;
}

.icon-ic_arrow-breadcrumbs:before {
    content: '\E013';
    font-size: 24px;
}

.icon-ic_arrow-down:before {
    content: '\E014';
    font-size: 24px;
}

.icon-ic_arrow-forward:before {
    content: '\E015';
    font-size: 24px;
}

.icon-ic_arrow-up:before {
    content: '\E016';
    font-size: 24px;
}

.icon-ic_arrow:before {
    content: '\E017';
    font-size: 24px;
}

.icon-ic_arrow_left_slider_preview:before {
    content: '\E018';
    font-size: 24px;
}

.icon-ic_arrow_right_circle:before {
    content: '\E019';
    font-size: 24px;
}

.icon-ic_arrow_rigth_slider_preview:before {
    content: '\E01A';
    font-size: 24px;
}

.icon-ic_attach_file:before {
    content: '\E01B';
    font-size: 24px;
}

.icon-ic_attach_file_tilted:before {
    content: '\E01C';
    font-size: 24px;
}

.icon-ic_attach_money:before {
    content: '\E01D';
    font-size: 24px;
}

.icon-ic_basket:before {
    content: '\E01E';
    font-size: 24px;
}

.icon-ic_block:before {
    content: '\E01F';
    font-size: 24px;
}

.icon-ic_box:before {
    content: '\E020';
    font-size: 24px;
}

.icon-ic_brush:before {
    content: '\E021';
    font-size: 24px;
}

.icon-ic_business:before {
    content: '\E022';
    font-size: 24px;
}

.icon-ic_calc:before {
    content: '\E023';
    font-size: 24px;
}

.icon-ic_call:before {
    content: '\E024';
    font-size: 24px;
}

.icon-ic_cancel:before {
    content: '\E025';
    font-size: 24px;
}

.icon-ic_center-horizontally:before {
    content: '\E026';
    font-size: 24px;
}

.icon-ic_center-vertically:before {
    content: '\E027';
    font-size: 24px;
}

.icon-ic_check-mark:before {
    content: '\E028';
    font-size: 24px;
}

.icon-ic_check_box:before {
    content: '\E029';
    font-size: 24px;
}

.icon-ic_check_box_outline:before {
    content: '\E02A';
    font-size: 24px;
}

.icon-ic_check_circle:before {
    content: '\E02B';
    font-size: 24px;
}

.icon-ic_check_offline:before {
    content: '\E02C';
    font-size: 24px;
}

.icon-ic_check_online:before {
    content: '\E02D';
    font-size: 24px;
}

.icon-ic_chrome_reader_mode:before {
    content: '\E02E';
    font-size: 24px;
}

.icon-ic_circle-plus:before {
    content: '\E02F';
    font-size: 24px;
}

.icon-ic_close:before {
    content: '\E030';
    font-size: 24px;
}

.icon-ic_code:before {
    content: '\E031';
    font-size: 24px;
}

.icon-ic_color-picker:before {
    content: '\E032';
    font-size: 24px;
}

.icon-ic_color:before {
    content: '\E033';
    font-size: 24px;
}

.icon-ic_comparison:before {
    content: '\E034';
    font-size: 24px;
}

.icon-ic_contact_calendar:before {
    content: '\E035';
    font-size: 24px;
}

.icon-ic_copy:before {
    content: '\E036';
    font-size: 24px;
}

.icon-ic_copyright:before {
    content: '\E037';
    font-size: 24px;
}

.icon-ic_cross-close:before {
    content: '\E038';
    font-size: 24px;
}

.icon-ic_cross-plus:before {
    content: '\E039';
    font-size: 24px;
}

.icon-ic_date_range:before {
    content: '\E03A';
    font-size: 24px;
}

.icon-ic_delete_forever:before {
    content: '\E03B';
    font-size: 24px;
}

.icon-ic_done:before {
    content: '\E03C';
    font-size: 24px;
}

.icon-ic_done_circle:before {
    content: '\E03D';
    font-size: 24px;
}

.icon-ic_dots:before {
    content: '\E03E';
    font-size: 24px;
}

.icon-ic_edit:before {
    content: '\E03F';
    font-size: 24px;
}

.icon-ic_exit_to_app:before {
    content: '\E040';
    font-size: 24px;
}

.icon-ic_eye_passw:before {
    content: '\E041';
    font-size: 24px;
}

.icon-ic_favorite:before {
    content: '\E042';
    font-size: 24px;
}

.icon-ic_favorite_border:before {
    content: '\E043';
    font-size: 24px;
}

.icon-ic_fiber_new:before {
    content: '\E044';
    font-size: 24px;
}

.icon-ic_figure-arrow-rigth-big:before {
    content: '\E045';
    font-size: 24px;
}

.icon-ic_figure-arrow-rigth:before {
    content: '\E046';
    font-size: 24px;
}

.icon-ic_figure-circle-talk:before {
    content: '\E047';
    font-size: 24px;
}

.icon-ic_figure-circle:before {
    content: '\E048';
    font-size: 24px;
}

.icon-ic_figure-crown:before {
    content: '\E049';
    font-size: 24px;
}

.icon-ic_figure-daimond:before {
    content: '\E04A';
    font-size: 24px;
}

.icon-ic_figure-eight-angle:before {
    content: '\E04B';
    font-size: 24px;
}

.icon-ic_figure-five-angle:before {
    content: '\E04C';
    font-size: 24px;
}

.icon-ic_figure-plant-leaf:before {
    content: '\E04D';
    font-size: 24px;
}

.icon-ic_figure-rectangle-talk:before {
    content: '\E04E';
    font-size: 24px;
}

.icon-ic_figure-square:before {
    content: '\E04F';
    font-size: 24px;
}

.icon-ic_figure-squre-border-radius-10:before {
    content: '\E050';
    font-size: 24px;
}

.icon-ic_figure-star:before {
    content: '\E051';
    font-size: 24px;
}

.icon-ic_figure-stars:before {
    content: '\E052';
    font-size: 24px;
}

.icon-ic_figure-triangle:before {
    content: '\E053';
    font-size: 24px;
}

.icon-ic_figures:before {
    content: '\E054';
    font-size: 24px;
}

.icon-ic_filters:before {
    content: '\E055';
    font-size: 24px;
}

.icon-ic_filter_list:before {
    content: '\E056';
    font-size: 24px;
}

.icon-ic_filter_list2:before {
    content: '\E057';
    font-size: 24px;
}

.icon-ic_flag:before {
    content: '\E058';
    font-size: 24px;
}

.icon-ic_flip-horizontally:before {
    content: '\E059';
    font-size: 24px;
}

.icon-ic_flip-vertically:before {
    content: '\E05A';
    font-size: 24px;
}

.icon-ic_fullscreen:before {
    content: '\E05B';
    font-size: 24px;
}

.icon-ic_fullscreen_exit:before {
    content: '\E05C';
    font-size: 24px;
}

.icon-ic_grab:before {
    content: '\E05D';
    font-size: 24px;
}

.icon-ic_group:before {
    content: '\E05E';
    font-size: 24px;
}

.icon-ic_help_fill:before {
    content: '\E05F';
    font-size: 24px;
}

.icon-ic_help_filled:before {
    content: '\E060';
    font-size: 24px;
}

.icon-ic_help_outline_24:before {
    content: '\E061';
    font-size: 24px;
}

.icon-ic_inari_logo_min:before {
    content: '\E062';
    font-size: 24px;
}

.icon-ic_indeterminate_check_box:before {
    content: '\E063';
    font-size: 24px;
}

.icon-ic_info-cursive:before {
    content: '\E064';
    font-size: 24px;
}

.icon-ic_info:before {
    content: '\E065';
    font-size: 24px;
}

.icon-ic_info_outline:before {
    content: '\E066';
    font-size: 24px;
}

.icon-ic_insert_drive_file:before {
    content: '\E067';
    font-size: 24px;
}

.icon-ic_keyboard_arrow_down:before {
    content: '\E068';
    font-size: 24px;
}

.icon-ic_keyboard_arrow_left_18:before {
    content: '\E069';
    font-size: 24px;
}

.icon-ic_keyboard_arrow_right:before {
    content: '\E06A';
    font-size: 24px;
}

.icon-ic_keyboard_arrow_up:before {
    content: '\E06B';
    font-size: 24px;
}

.icon-ic_label_location:before {
    content: '\E06C';
    font-size: 24px;
}

.icon-ic_label_rectangle_location:before {
    content: '\E06D';
    font-size: 24px;
}

.icon-ic_layers:before {
    content: '\E06E';
    font-size: 24px;
}

.icon-ic_letter-t:before {
    content: '\E06F';
    font-size: 24px;
}

.icon-ic_link:before {
    content: '\E070';
    font-size: 24px;
}

.icon-ic_link_2:before {
    content: '\E071';
    font-size: 24px;
}

.icon-ic_local_offer:before {
    content: '\E072';
    font-size: 24px;
}

.icon-ic_mail_outline:before {
    content: '\E073';
    font-size: 24px;
}

.icon-ic_map_location:before {
    content: '\E074';
    font-size: 24px;
}

.icon-ic_map_location_empty:before {
    content: '\E075';
    font-size: 24px;
}

.icon-ic_menu_24:before {
    content: '\E076';
    font-size: 24px;
}

.icon-ic_minus:before {
    content: '\E077';
    font-size: 24px;
}

.icon-ic_monetization:before {
    content: '\E078';
    font-size: 24px;
}

.icon-ic_more_horiz:before {
    content: '\E079';
    font-size: 24px;
}

.icon-ic_move:before {
    content: '\E07A';
    font-size: 24px;
}

.icon-ic_my_location:before {
    content: '\E07B';
    font-size: 24px;
}

.icon-ic_nature_people:before {
    content: '\E07C';
    font-size: 24px;
}

.icon-ic_paper:before {
    content: '\E07D';
    font-size: 24px;
}

.icon-ic_pencil:before {
    content: '\E07E';
    font-size: 24px;
}

.icon-ic_perm_data_setting:before {
    content: '\E07F';
    font-size: 24px;
}

.icon-ic_phonelink_lock:before {
    content: '\E080';
    font-size: 24px;
}

.icon-ic_photo:before {
    content: '\E081';
    font-size: 24px;
}

.icon-ic_picture:before {
    content: '\E082';
    font-size: 24px;
}

.icon-ic_play:before {
    content: '\E083';
    font-size: 24px;
}

.icon-ic_play_circle_outline:before {
    content: '\E084';
    font-size: 24px;
}

.icon-ic_plus:before {
    content: '\E085';
    font-size: 24px;
}

.icon-ic_power:before {
    content: '\E086';
    font-size: 24px;
}

.icon-ic_product:before {
    content: '\E087';
    font-size: 24px;
}

.icon-ic_qr-code:before {
    content: '\E088';
    font-size: 24px;
}

.icon-ic_question:before {
    content: '\E089';
    font-size: 24px;
}

.icon-ic_refresh_black:before {
    content: '\E08A';
    font-size: 24px;
}

.icon-ic_remove:before {
    content: '\E08B';
    font-size: 24px;
}

.icon-ic_revert-close:before {
    content: '\E08C';
    font-size: 24px;
}

.icon-ic_rotate-phone:before {
    content: '\E08D';
    font-size: 24px;
}

.icon-ic_rotate:before {
    content: '\E08E';
    font-size: 24px;
}

.icon-ic_save:before {
    content: '\E08F';
    font-size: 24px;
}

.icon-ic_save_2:before {
    content: '\E090';
    font-size: 24px;
}

.icon-ic_school:before {
    content: '\E091';
    font-size: 24px;
}

.icon-ic_search:before {
    content: '\E092';
    font-size: 24px;
}

.icon-ic_settings:before {
    content: '\E093';
    font-size: 24px;
}

.icon-ic_share_link:before {
    content: '\E094';
    font-size: 24px;
}

.icon-ic_shield:before {
    content: '\E095';
    font-size: 24px;
}

.icon-ic_shopping_cart:before {
    content: '\E096';
    font-size: 24px;
}

.icon-ic_size:before {
    content: '\E097';
    font-size: 24px;
}

.icon-ic_sort:before {
    content: '\E098';
    font-size: 24px;
}

.icon-ic_star:before {
    content: '\E099';
    font-size: 24px;
}

.icon-ic_store:before {
    content: '\E09A';
    font-size: 24px;
}

.icon-ic_stretch:before {
    content: '\E09B';
    font-size: 24px;
}

.icon-ic_subject:before {
    content: '\E09C';
    font-size: 24px;
}

.icon-ic_support:before {
    content: '\E09D';
    font-size: 24px;
}

.icon-ic_text-align-center:before {
    content: '\E09E';
    font-size: 24px;
}

.icon-ic_text-align-justify:before {
    content: '\E09F';
    font-size: 24px;
}

.icon-ic_text-align-left:before {
    content: '\E0A0';
    font-size: 24px;
}

.icon-ic_text-align-right:before {
    content: '\E0A1';
    font-size: 24px;
}

.icon-ic_text-bold:before {
    content: '\E0A2';
    font-size: 24px;
}

.icon-ic_text-italic:before {
    content: '\E0A3';
    font-size: 24px;
}

.icon-ic_text:before {
    content: '\E0A4';
    font-size: 24px;
}

.icon-ic_thumb:before {
    content: '\E0A5';
    font-size: 24px;
}

.icon-ic_transformation:before {
    content: '\E0A6';
    font-size: 24px;
}

.icon-ic_trash:before {
    content: '\E0A7';
    font-size: 24px;
}

.icon-ic_view_list:before {
    content: '\E0A8';
    font-size: 24px;
}

.icon-ic_warning:before {
    content: '\E0A9';
    font-size: 24px;
}

.icon-if_category:before {
    content: '\E0AA';
    font-size: 24px;
}

.icon-instagram-with-circle:before {
    content: '\E0AB';
    font-size: 24px;
}

.icon-instagram:before {
    content: '\E0AC';
    font-size: 24px;
}

.icon-line-space:before {
    content: '\E0AD';
    font-size: 24px;
}

.icon-lock:before {
    content: '\E0AE';
    font-size: 24px;
}

.icon-magic-wand:before {
    content: '\E0AF';
    font-size: 24px;
}

.icon-marker:before {
    content: '\E0B0';
    font-size: 24px;
}

.icon-more:before {
    content: '\E0B1';
    font-size: 24px;
}

.icon-odnoklassniki-logo-share:before {
    content: '\E0B2';
    font-size: 24px;
}

.icon-odnoklassniki-logo:before {
    content: '\E0B3';
    font-size: 24px;
}

.icon-ok:before {
    content: '\E0B4';
    font-size: 24px;
}

.icon-partners:before {
    content: '\E0B5';
    font-size: 24px;
}

.icon-pdf:before {
    content: '\E0B6';
    font-size: 24px;
}

.icon-star:before {
    content: '\E0B7';
    font-size: 24px;
}

.icon-telegramm-logo-share:before {
    content: '\E0B8';
    font-size: 24px;
}

.icon-text-arc:before {
    content: '\E0B9';
    font-size: 24px;
}

.icon-text-letter-spacing:before {
    content: '\E0BA';
    font-size: 24px;
}

.icon-tt:before {
    content: '\E0BB';
    font-size: 24px;
}

.icon-twitter-with-circle:before {
    content: '\E0BC';
    font-size: 24px;
}

.icon-viber:before {
    content: '\E0BD';
    font-size: 24px;
}

.icon-vimeo-with-circle:before {
    content: '\E0BE';
    font-size: 24px;
}

.icon-vk-logo-share:before {
    content: '\E0BF';
    font-size: 24px;
}

.icon-vk-logo:before {
    content: '\E0C0';
    font-size: 24px;
}

.icon-vk-with-circle:before {
    content: '\E0C1';
    font-size: 24px;
}

.icon-vk:before {
    content: '\E0C2';
    font-size: 24px;
}

.icon-yookassa:before {
    content: '\E0C3';
    font-size: 24px;
}

.icon-youtube-logo:before {
    content: '\E0C4';
    font-size: 24px;
}

.icon-youtube:before {
    content: '\E0C5';
    font-size: 24px;
}

.icon-zoom_in:before {
    content: '\E0C6';
    font-size: 24px;
}
