@import '../_mixins/mq';
@import './../../styles/_vars/colors';

$form-title-color: $c-dark-gray;

.g-form-error {
    margin-bottom: 20px;
    &__title {
        display: block;
        margin-bottom: 2px;
        color: $c-red;
    }
}

.g-form-error-box {
    position: relative;
    padding: 10px 60px 10px 34px;
    border-radius: 4px;
    font-size: 13px;
    line-height: 18px;
    color: $c-white;
    background-color: $c-red;

    p {
        margin: 0;
        padding: 0;
    }

    .icon {
        position: absolute;
        right: 17px;
        top: 50%;
        margin-top: -12px;
        color: $c-white;

        &::before {
            font-size: 24px;
        }
    }
}

.g-form-button {
    text-align: left;
    &--right {
        text-align: right;
    }
    &--center {
        text-align: center;
    }
    &--justify {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @include mq($until: tablet) {
        .g-btn,
        .g-btn-link {
            display: block;
            width: 100%;
        }
    }
}

.g-form-inline {
    .g-form-group {
        display: inline-block;

        & + .g-form-group {
            margin-left: 22px;
        }
    }
}

.g-form-section {
    margin-bottom: 40px;

    .g-form-group {
        margin-bottom: 25px;
    }

    &--margin-sm {
        margin-bottom: 15px;
    }
}

.g-album-link {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    i.icon {
        display: inline-block;
        vertical-align: middle;
        color: $c-red;
        margin-right: 5px;
    }

    a {
        display: inline-block;
        vertical-align: middle;
        line-height: 16px;
        color: $c-blue;
    }
}

.g-blue-link {
    font-weight: 300;
    color: $c-blue !important;
    border-bottom: 1px solid $c-light-blue;
    transition: border-color 0.2s ease-in-out;
    line-height: 16px;

    &:hover {
        border-color: $c-blue;
    }
}

.g-form-highlight {
    background-color: $c-extra-light-gray;
    margin-bottom: 30px;
    padding-bottom: 20px;
}

.g-form-symbols-counter {
    position: absolute;
    top: -5px;
    right: 0;
    font-size: 10px;
    color: $c-medium-gray;
}

.g-search {
    width: 100%;
    border: 1px solid $c-light-gray;
    border-radius: 20px;
    height: 38px;
    background-color: $c-white;
    font-size: 14px;
    font-family: inherit;
    padding: 0 45px;

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: $c-medium-gray;
    }
}

.g-form-file-list {
    margin-bottom: 13px;
    &__el {
        margin-bottom: 5px;
        height: 20px;
        line-height: 20px;
        padding-right: 15px;
        display: inline-block;

        .file-name {
            color: $c-dark-gray;
            font-size: 13px;
            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-left: 12px;
            margin-right: 12px;
            display: inline-block;
            vertical-align: middle;
        }

        .close-button {
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;
            i.icon {
                color: $c-red;
            }

            &:hover {
                i.icon {
                    color: darken($c-red, 7%);
                }
            }

            &:active {
                i.icon {
                    color: darken($c-red, 10%);
                }
            }
        }

        i.icon {
            color: $c-dark-gray;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.g-form-file-input {
    position: relative;
    margin-bottom: 15px;
    font-weight: 600;
    input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
    }
    label {
        cursor: pointer;
        &:hover {
            > span {
                color: $c-red;
                border-bottom-color: transparent;
            }
        }
        i.icon {
            color: $c-red;
            margin-right: 10px;
        }
        > span {
            display: inline-block;
            font-size: 13px;
            line-height: 18px;
            color: $c-blue;
            border-bottom: 1px dashed $c-medium-gray;
            vertical-align: middle;
        }
    }
    small {
        display: block;
        font-size: 12px;
        line-height: 14px;
        padding-left: 34px;
        color: $c-medium-gray;
    }
}

.g-crashed {
    color: $c-red;
    font-size: 13px;
    display: inline-flex;
    align-items: center;

    > i.icon {
        margin-right: 5px;
        &:before {
            font-size: 20px;
        }
    }
}

.g-crashed-container {
    display: flex;
    align-items: center;
}

.g-btn-crashed {
    border: 1px solid $c-red !important;
}
